<template>
  <div class="box" v-loading="loading">
    <div v-for="(item, index) in dataList" :key="index" class="item">
      <img :src="item.img_url" :alt="$t('siffa')" class="img" :class="{cursor:item.link_url}"
           @click="handleClick(item)">
    </div>
  </div>
</template>

<script>
export default {
  name: "advertising",
  data() {
    return {
      loading: false,
      dataList: [],
      params: {
        platform: 1,
        language: this.LOCALE == "en" ? 1 : 2,
        type: 1,
        position: 4,
        related_id: 60,
        usage: 2,
        nopage: 0,
        limit: 2,
        start: 1
      }
    }
  },
  mounted() {

    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.loading = true
      let params = {
        language: this.LOCALE == "en" ? 1 : 2,
        source: this.PJSource,
        serial_no: "7,8"
      }
      let res = await this.$store.dispatch("API_position/advertising_Info", params);
      this.loading = false
      if (res.success) {
        this.dataList = res.data
      }
    },
    handleClick(item) {
      if (item.link_url) {
        let url = item.link_url
        if (item.link_url.indexOf('http') == -1) {
          url = 'http://' + item.link_url
        }
        window.open(url)
      }
    }
  }
}
</script>

<style scoped lang="less">
.box {
  margin-top: 16px;
}

.item {
  width: 300px;
  height: 200px;
  /*cursor: pointer;*/
  margin-bottom: 16px;

  .img {
    width: 100%;
    height: 100%;
  }
}
</style>