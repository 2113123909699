<template>
  <div>
    <div class="creditBanner">
      <div class="creditBannerImg">
        <div
            class="bannerInner"
            :class="{
            pointer:
              advertising1.link_url &&
              advertising1.extend &&
              !advertising1.extend.is_show,
          }"
            :style="{ 'background-image': 'url(' + advertising1.img_url + ')' }"
        >
          <div
              class="bannerHerf"
              v-if="
              advertising1.link_url &&
              advertising1.extend &&
              !advertising1.extend.is_show
            "
          >
            <a
                :href="advertising1.link_url"
                target="_blank"
                rel="nofollow"
                v-if="
                advertising1.link_url &&
                advertising1.link_url.indexOf('http') == '0'
              "
            ></a>
            <a :href="advertising1.link_url" rel="nofollow" v-else></a>
          </div>
          <div
              v-if="advertising1.extend && advertising1.extend.is_show"
              class="bannerBtn"
              :class="'btnStyle' + advertising1.extend.buttonStyle"
              :style="{
              bottom: `${advertising1.extend.bottom}%`,
              right: `${advertising1.extend.right}%`,
              background: advertising1.extend.backgroundColor,
              borderColor: advertising1.extend.borderColor,
            }"
          >
            <!-- @click="handleLink(advertising1.link_url)" -->
            <a
                :style="{
                fontSize: `0.${advertising1.extend.fontSize}rem`,
                color: advertising1.extend.color,
              }"
                :href="advertising1.link_url"
                target="_blank"
                rel="nofollow"
                v-if="
                advertising1.link_url &&
                advertising1.link_url.indexOf('http') == '0'
              "
            >
              {{ advertising1.extend.content }}</a
            >
            <a
                :href="advertising1.link_url"
                rel="nofollow"
                :style="{
                fontSize: `0.${advertising1.extend.fontSize}rem`,
                color: advertising1.extend.color,
              }"
                v-else
            >
              {{ advertising1.extend.content }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "advertisementBanner",
  props:{
    advertising1:{
      type:Object,
      default:function(){
        return null
      }
    }
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.creditBanner {
  width: 100%;
  padding-top: 14.6%;
  position: relative;
  margin-bottom: 30px;
.creditBannerImg {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
.bannerInner {
  height: 100%;
  background-size: 100% 100%;
  position: relative;
.bannerHerf {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
a {
  display: block;
  height: 100%;
  width: 100%;
}
}
.bannerBtn {
  position: absolute;

  padding: 0px 20px;
  border: 1px solid;
  border-radius: 4px;
  transform: translate(50%, 50%);
&.btnStyle1 {
   border-radius: 0px;
 }
&.btnStyle2 {
   border-radius: 4px;
 }
&.btnStyle3 {
   border-radius: 999px;
 }
}
}
}
}
.pointer {
  cursor: pointer;
}
</style>